import React from "react"

export default function Logo() {
    return (
        <svg role="img" aria-label="splatapozyczek.pl" width="142" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g className="leters">
                <path d="M27.3206 14.2041H21.47L20.0742 12.8361V10.6786H22.2391V12.3979H26.5539V9.01284H21.523L20.1446 7.64484V3.37954L21.5222 2.01154H27.2304L28.6262 3.37954V5.72933H26.4621V3.82158H22.2929V7.20667H27.3206L28.6982 8.57467V12.84L27.3206 14.2041Z" fill="#050505" />
                <path d="M32.2574 17.7727H30.1851V4.98538H32.0487V5.98253L33.0606 4.98538H36.5415L37.9169 6.30857V12.9086L36.5415 14.2493H32.2574V17.7727ZM35.8276 6.89455H33.0237L32.2574 7.54741V12.3773H35.8276V6.89455Z" fill="#050505" />
                <path d="M41.486 14.2041H39.4043V1.41679H41.486V14.2041Z" fill="#050505" />
                <path d="M50.7045 6.30213V14.2042H48.9743V13.1778L47.9637 14.2042H44.6063L43.27 12.87V10.3386L44.6063 9.00295H48.7175V6.74685H45.3086V7.8246H43.3556V6.30213L44.7093 4.98538H49.3856L50.7045 6.30213ZM48.7175 10.1851H45.257V12.5622H48.0001L48.7198 11.9291L48.7175 10.1851Z" fill="#050505" />
                <path d="M57.8416 14.2041H54.5263L53.1662 12.8521V6.52592H51.894V4.86198H53.1662V2.90372H55.241V4.86198H57.682V6.52592H55.2433V12.2804H57.8416V14.2041Z" fill="#050505" />
                <path d="M66.4658 6.30213V14.2042H64.6665V13.1778L63.6149 14.2042H60.1235L58.7339 12.87V10.3386L60.1235 9.00295H64.3987V6.74685H60.8537V7.8246H58.8229V6.30213L60.2306 4.98538H65.0935L66.4658 6.30213ZM64.3987 10.1851H60.8002V12.5622H63.6527L64.4002 11.9291L64.3987 10.1851Z" fill="#050505" />
                <path d="M74.2241 14.2041H72.1157V2.01154H79.1314L80.4424 3.37997V8.32619L78.587 10.2563H74.2271L74.2241 14.2041ZM74.2241 8.36188H77.6472L78.3453 7.6637V3.99437H74.2241V8.36188Z" fill="#050505" />
                <path d="M83.276 4.98538H88.3143L89.6611 6.30213V12.87L88.3143 14.2042H83.276L81.9292 12.87V6.30213L83.276 4.98538ZM87.5794 12.3398V6.83231H84.0109V12.3398H87.5794Z" fill="#050505" />
                <path d="M97.9869 14.2042H90.8506V11.7257L95.5897 7.14013V6.62735H90.9059V4.98538H97.8341V7.46535L93.0735 12.0146V12.544H97.9877L97.9869 14.2042Z" fill="#050505" />
                <path d="M99.2976 14.7478H101.264V16.0193H104.843V13.7165H100.52L99.1772 12.3758V4.98538H101.244V11.9464H104.843V4.98538H106.909V16.432L105.548 17.7727H100.641L99.2976 16.432V14.7478Z" fill="#050505" />
                <path d="M113.811 12.3368V10.6781H115.83V12.8706L114.472 14.2042H109.736L108.396 12.8706V6.30159L109.736 4.98538H114.472L115.83 6.30159V8.4721H113.811V6.83155H110.433V12.3368H113.811Z" fill="#050505" />
                <path d="M124.157 14.2042H116.723V11.7257L121.661 7.14165V6.62886H116.777V4.98538H123.994V7.46535L119.035 12.0146V12.544H124.154L124.157 14.2042Z" fill="#050505" />
                <path d="M130.807 12.5108V11.0397H132.781V12.87L131.459 14.2042H126.669L125.347 12.87V6.30213L126.669 4.98538H131.459L132.781 6.30213V9.97935H127.356V12.5108H130.807ZM130.807 6.66138H127.356V8.85471H130.807V6.66138Z" fill="#050505" />
                <path d="M136.393 14.2034H134.268V1.41679H136.393V8.89118L139.714 6.13677V4.8774H141.857V6.78023L139.375 8.80472L142 12.2317V14.2041H139.84V12.7336L137.893 10.0511L136.393 11.2623V14.2034Z" fill="#050505" />
            </g>
            <g>
                <path d="M14.4241 11.5277H1.33936V14.7989H14.4241V11.5277Z" fill="#FCCF4F" />
                <path d="M16.685 8.46112L4.0542 5.77635L3.3162 9.24837L15.947 11.9331L16.685 8.46112Z" fill="#FCCF4F" />
                <path d="M13.0781 0.227313L0.447266 2.91208L1.18527 6.3841L13.8161 3.69933L13.0781 0.227313Z" fill="#FCCF4F" />
            </g>
        </svg>
    )
}